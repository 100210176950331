import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '@/css/app.css';

class App {
    menuButton = document.getElementById('menu-button');
    menuButtonOpen = this.menuButton!.querySelector('.menu-button-open');
    menuButtonClose = this.menuButton!.querySelector('.menu-button-close');
    termButtons = document.querySelectorAll('.term');
    
    public async init() {
        this.menuButton?.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('menu-open');
            this.toggleMenuButton();
        })

        this.termButtons?.forEach((button) => {
            button.addEventListener('click', (e) => {
                const elm:HTMLElement = e.currentTarget as HTMLElement;
                if (elm) {
                    elm.classList.add('active');
                }
            });
        });
    }

    private toggleMenuButton() {
        this.menuButtonOpen!.classList.toggle('hidden');
        this.menuButtonClose!.classList.toggle('hidden');
    }
}

let app = new App();
app.init();

/* Check for sticky */
// const block = document.querySelector('.block-section-menu') as HTMLElement;
// const observer:IntersectionObserver = new IntersectionObserver(
//     ([e]) => {
//         console.log('IntersectionObserver', e)
//         e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
//     },
//     {
//         rootMargin: "0px 0px 0px 0px",
//         threshold: 0,
//     }
// );
// observer.observe(block);

/* Accordion */
const accordionItems = document.querySelectorAll(".accordion-item");

accordionItems.forEach(item =>
  item.addEventListener("click", () => {
    const isItemOpen = item.classList.contains("open");
    accordionItems.forEach(item => item.classList.remove("open"));
    if (!isItemOpen) {
      item.classList.toggle("open");
    }
  })
);

/* Cookie Consent */
CookieConsent.run({
    onFirstConsent: ({cookie}) => {
        window?.dataLayer?.push({ 'event': 'client-consent-update' });
    },
    onChange: ({cookie}) => {
        window?.dataLayer?.push({ 'event': 'client-consent-update' });
    },
    guiOptions: {
        consentModal: {
            layout: "box inline",
            position: "bottom right",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        functionality: {},
        analytics: {},
        marketing: {}
    },
    language: {
        default: "nl",
        autoDetect: "document",
        translations: {
            nl: {
                consentModal: {
                    title: "Het Speelgoedmuseum gebruikt cookies om je surfgedrag te verbeteren.",
                    description: "Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Wilt u liever zelf kiezen welke cookies u aanvaardt? Klik op ‘Cookievoorkeuren beheren’. Meer info leest u in het privacy- en cookiebeleid van Speelgoedmuseum.be",
                    acceptAllBtn: "Alles aanvaarden",
                    acceptNecessaryBtn: "Alles weigeren",
                    showPreferencesBtn: "Voorkeuren aanpassen",
                    footer: "<a href=\"/privacybeleid\">Privacybeleid</a>\n<a href=\"/algemene-voorwaarden\">Algemende voorwaarden</a>"
                },
                preferencesModal: {
                    title: "Cookievoorkeuren",
                    acceptAllBtn: "Alles aanvaarden",
                    acceptNecessaryBtn: "Alles weigeren",
                    savePreferencesBtn: "Voorkeuren aanpassen",
                    closeIconLabel: "Venster sluiten",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookiegebruik",
                            description: "Sommige cookies zijn noodzakelijk om de website optimaal te laten functioneren. Andere cookies zijn optioneel en worden gebruikt om uw surfervaring op deze website te verbeteren. Als u op ‘Alle cookies aanvaarden’ klikt, aanvaardt u ook de optionele cookies. Wilt u liever zelf kiezen welke cookies u aanvaardt? Klik op ‘Cookievoorkeuren beheren’. Meer info leest u in het privacy- en cookiebeleid van Speelgoedmuseum.be"
                        },
                        {
                            title: "Noodzakelijke cookies <span class=\"pm__badge\">Steeds ingeschakeld</span>",
                            description: "Deze cookies zijn vereist om de website goed te laten werken en zorgen ervoor dat uw cookievoorkeuren worden bewaard.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytische cookies",
                            description: "Deze cookies meten het aantal bezoekers op de website en geven een algemeen beeld van uw bezoek. Alle verzamelde gegevens zijn anoniem.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Media cookies",
                            description: "Media cookies zijn een vorm van marketing cookies. Deze cookies worden gebruikt om de bezoekers over websites heen te volgen.",
                            linkedCategory: "marketing"
                        }
                    ]
                },
            },
            fr: {
                consentModal: {
                    title: "Le Musée du Jouet de Malines utilise des cookies pour améliorer votre navigation.",
                    description: "Certains cookies sont nécessaires pour garantir le fonctionnement optimal du site web. Les autres cookies sont optionnels et sont utilisés pour améliorer votre expérience de navigation sur ce site web. Si vous cliquez sur « Accepter tous les cookies », vous acceptez également les cookies optionnels. Vous préférez choisir vous-même quels cookies vous acceptez ? Cliquez sur « Gérer les préférences en matière de cookies ». Pour plus d'informations, veuillez consulter la politique de confidentialité et la politique en matière de cookies de Speelgoedmuseum.be",
                    acceptAllBtn: "Tout accepter",
                    acceptNecessaryBtn: "Tout refuser",
                    showPreferencesBtn: "Sauvegarder mes préférences",
                    footer: "<a href=\"/fr/polotique-de-confidentialité\">Polotique de confidentialité</a>\n<a href=\"/fr/conditions-générales\">Conditions générales</a>"
                },
                preferencesModal: {
                    title: "Préférences en matière de cookies",
                    acceptAllBtn: "Tout accepter",
                    acceptNecessaryBtn: "Tout refuser",
                    savePreferencesBtn: "Sauvegarder mes préférences",
                    closeIconLabel: "Fermez la fenêtre",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Utilisation des cookies",
                            description: "Certains cookies sont nécessaires pour garantir le fonctionnement optimal du site web. Les autres cookies sont optionnels et sont utilisés pour améliorer votre expérience de navigation sur ce site web. Si vous cliquez sur « Accepter tous les cookies », vous acceptez également les cookies optionnels. Vous préférez choisir vous-même quels cookies vous acceptez ? Cliquez sur « Gérer les préférences en matière de cookies ». Pour plus d'informations, veuillez consulter la politique de confidentialité et la politique en matière de cookies de Speelgoedmuseum.be",
                        },
                        {
                            title: "Cookies nécessaires <span class=\"pm__badge\">Toujours allumé</span>",
                            description: "Ces cookies sont nécessaires au bon fonctionnement du site web et permettent de sauvegarder vos préférences en matière de cookies.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Cookies analytiques",
                            description: "Ces cookies comptent le nombre de visiteurs sur le site web et offrent une image globale de votre visite. Toutes les données collectées sont anonymes.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Cookies médias sociaux",
                            description: "Les cookies médias sociaux sont une forme de cookies marketing. Ces cookies sont utilisés pour suivre les visiteurs sur les sites web.",
                            linkedCategory: "marketing"
                        }
                    ]
                },
            },
            en: {
                consentModal: {
                    title: "Toy Museum Mechelen uses cookies to improve your browsing behaviour.",
                    description: "Some cookies are necessary to ensure that the website functions optimally. Other cookies are optional and are used to improve your browsing experience on this website. If you click on ‘Accept all cookies’, you also accept the optional cookies. Would you prefer to choose which cookies you accept yourself? Click on ‘Manage cookie preferences’. For more information, please consult the privacy policy and cookie policy of Speelgoedmuseum.be.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Save my preferences",
                    footer: "<a href=\"/en/privacy-policy\">Privacy policy</a>\n<a href=\"/en/general-terms-and-conditions\">General terms and conditions</a>"
                },
                preferencesModal: {
                    title: "Cookie preferences",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save my preferences",
                    closeIconLabel: "Close window",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Use of cookies",
                            description: "Some cookies are necessary to ensure that the website functions optimally. Other cookies are optional and are used to improve your browsing experience on this website. If you click on ‘Accept all cookies’, you also accept the optional cookies. Would you prefer to choose which cookies you accept yourself? Click on ‘Manage cookie preferences’. For more information, please consult the privacy policy and cookie policy of Speelgoedmuseum.be.",
                        },
                        {
                            title: "Necessary cookies <span class=\"pm__badge\">Always enabled</span>",
                            description: "These cookies are necessary for the proper functioning of the website and allow you to save your cookie preferences.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytical cookies",
                            description: "These cookies count the number of visitors to the website and provide an overall picture of your visit. All data collected is anonymous.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "Social media cookies",
                            description: "Social media cookies are a form of marketing cookie. These cookies are used to track visitors to websites.",
                            linkedCategory: "marketing"
                        }
                    ]
                },
            },
        }
    }
});


// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}